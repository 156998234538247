/* Font Family starts here */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,100&family=Nunito:wght@300;400;500;600;700&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

@font-face {
	font-family: "Baskerville";
	src: url("../fonts/BASKVILL.ttf");
}

/* Font Family starts here */
/* global css starts here */
.btn {
	background-image: linear-gradient(
		90deg,
		hsl(46deg 96% 57%) 0%,
		hsl(46deg 92% 62%) 9%,
		hsl(47deg 87% 66%) 18%,
		hsl(48deg 81% 70%) 27%,
		hsl(48deg 73% 71%) 36%,
		hsl(45deg 65% 63%) 45%,
		hsl(43deg 60% 56%) 55%,
		hsl(41deg 57% 50%) 64%,
		hsl(41deg 57% 52%) 73%,
		hsl(42deg 61% 59%) 82%,
		hsl(44deg 66% 66%) 91%,
		hsl(46deg 75% 73%) 100%
	);
	padding: 10px 40px;
	background-repeat: no-repeat;
	border-radius: 0px;
	color: #0c0200;
	font-weight: 500;
	transition: 0.3s;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

.line-clamp {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.line-clamp2 {
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.icon-style {
	display: flex;
	justify-content: flex-start;
	gap: 0.2rem;
	align-items: center;
	justify-content: flex-start;
}

.icon {
	font-size: 1.2rem;
	color: #c59531;
}

.cursor-pointer {
	cursor: pointer;
}

.empty-icon {
	font-size: 2.6rem;
}

.header-list ul li button.total-cart-icon {
	/* margin-right: 20px; */
	position: relative;
	background: transparent;
	border: none;
}
.header-list ul li a.total-cart-icon {
	/* margin-right: 20px; */
	position: relative;
	background: transparent;
	border: none;
}

.images_set img {
	width: 44px;
	border-radius: 44px;
	height: 44px;
	object-fit: contain;
}

.button_setImage {
	border: none;
	border-radius: 50px;
	width: 50px;
	height: 50px;
}

.header-list span.total-cart-no {
	background-image: linear-gradient(
		90deg,
		hsl(46deg 96% 43%) 0%,
		hsl(46deg 92% 62%) 9%,
		hsl(47deg 87% 66%) 18%,
		hsl(48deg 81% 70%) 27%,
		hsl(48deg 63% 49%) 36%,
		hsl(45deg 65% 63%) 45%,
		hsl(43deg 60% 56%) 55%,
		hsl(41deg 57% 50%) 64%,
		hsl(41deg 57% 52%) 73%,
		hsl(42deg 61% 59%) 82%,
		hsl(44deg 66% 66%) 91%,
		hsl(46deg 75% 73%) 100%
	);
	padding: 3px;
	width: 25px;
	height: 22px;
	color: #0c0200;
	display: inline-block;
	text-align: center;
	border-radius: 50%;
	line-height: 1.5;
	position: absolute;
	right: -12px;
	padding: 0 auto;
	top: -11px;
	font-weight: bold;
	font-size: 11px;
}

.header-list ul li .btnHeader {
	padding: 10px 10px;
	font-size: 13px;
	border-radius: 9px;
}

p {
	margin: 0;
	/* padding-left: 1.2rem; */
}

.btn:hover {
	background-image: linear-gradient(
		-90deg,
		hsl(46deg 96% 57%) 0%,
		hsl(46deg 92% 62%) 9%,
		hsl(47deg 87% 66%) 18%,
		hsl(48deg 81% 70%) 27%,
		hsl(48deg 73% 71%) 36%,
		hsl(45deg 65% 63%) 45%,
		hsl(43deg 60% 56%) 55%,
		hsl(41deg 57% 50%) 64%,
		hsl(41deg 57% 52%) 73%,
		hsl(42deg 61% 59%) 82%,
		hsl(44deg 66% 66%) 91%,
		hsl(46deg 75% 73%) 100%
	);
}

a:hover {
	text-decoration: none;
}

/* global css ends here */
/* typpography starts here */
* {
	font-family: "Poppins";
}

h1 {
	font-family: "Baskerville";
	font-size: 70px;
	line-height: 1.5;
	font-weight: 400;
}

h2 {
	font-family: "Baskerville";
	font-size: 50px;
	line-height: 1.5;
	font-weight: 400;
}

h3 {
	font-family: "Baskerville";
	font-size: 31px;
	line-height: 1.5;
	font-weight: 400;
}

.product-slide .slick-slide img {
	display: block;
	max-width: 100%;
	min-width: 100%;
}

.product-slide .nav-images img {
	border: 5px solid #fff;
	height: 100px;
	object-fit: cover;
}

.product-slide .slick-custom .slick-track {
	width: max-content !important;
}

h4 {
	font-family: "Poppins";
	font-size: 20px;
	line-height: 1.5;
}

h5 {
	font-family: "Poppins";
	font-size: 18px;
	line-height: 1.5;
}

h6 {
	font-family: "Poppins";
	font-size: 14px;
	line-height: 1.5;
	color: #2b2724;
}

p {
	font-family: "Poppins";
	font-size: 14px;
	font-weight: 400;
	line-height: 1.5;
	color: #534f4d;
}

a {
	font-family: "Poppins";
	font-size: 16px;
	font-weight: 400;
	line-height: 1.5;
}

/* typpography ends here */
/* Header starts here */
header#top ul.navbar-nav {
	flex-direction: row;
}

header#top {
	padding: 10px 0px;
	position: relative;
	top: 0px;
	z-index: 2;
	background: #000;
}

header#top ul {
	list-style: none;
	padding: 0px;
	margin: 0px;
	display: flex;
	justify-content: space-between;
}

header#top ul.navbar-nav li a {
	color: #ffffff;
}

header#top .nav-icons-wrapper ul {
	justify-content: right;
	align-items: center;
}

header#top .nav-icons-wrapper ul li {
	margin: 0 0 0 25px;
}

/* mobile header starts here */
header .mobile-header {
	width: 260px;
	height: 100%;
	position: fixed;
	background: #000;
	top: 0;
	z-index: 9999;
	right: 0;
	padding-top: 30px;
	transition: 0.7s;
	transform: translateX(113%);
	border: 3px solid #faca2c;
	outline: 15px solid #000;
	margin: 10px;
}

header .mobile-header.show {
	transform: translateX(0%);
}

header .mobile-header ul.mobile-nav {
	margin-bottom: 0;
	padding: 0;
	list-style: none;
}

header .mobile-header .cancel {
	background: #fff;
	width: 35px;
	text-align: center;
	height: 35px;
	line-height: 55px;
	margin: 0px 20px 20px auto;
}

header .mobile-header .cancel button.cancel-btns {
	padding: 0px;
	border: 0px;
	height: unset;
	background: transparent;
}

header .mobile-header ul.mobile-nav li.nav-item {
	padding: 10px 0;
	border: 0;
	border-bottom: 1px solid #dddddd4a;
}

header .mobile-header ul.mobile-nav li.nav-item a {
	color: #fff;
	font-size: 14px;
	padding: 0 20px;
}

.read-more-read-less .setButton {
	background: transparent;
	border: none;
	font-weight: 500;
	color: rgb(59, 59, 59);
	cursor: pointer;
	list-style: none;
	font-size: 15px;
}

@media (min-width: 991px) {
	header#top .canvas-icon {
		display: none !important;
	}
}

header#top .mobile-header ul.mobile-nav {
	display: flex;
	flex-direction: column;
}

header .mobile-header .cancel svg {
	position: relative;
	top: -11px;
}

/* mobile header ends here */
/* responsive starts here */
@media (max-width: 1100px) {
	header#top .canvas-icon {
		display: block;
		text-align: end;
	}

	header#top .nav-icons-wrapper {
		display: none;
	}

	header#top .navbar-wrapper {
		display: none;
	}

	header#top .canvas-icon {
		background-color: transparent;
		padding: 0px;
		border: 0px;
	}

	header#top .canvas-icon i {
		font-size: 28px;
		color: #fff;
	}

	header .mobile-header ul.mobile-nav li.nav-item button.total-cart-icon {
		background-color: transparent;
		border: 0px;
		color: #fff;
	}

	header
		.mobile-header
		ul.mobile-nav
		li.nav-item
		button.total-cart-icon
		span.total-cart-no {
		font-size: 23px;
		position: relative;
		left: 6px;
		top: 4px;
	}
}
@media (max-width: 768px) {
	header .mobile-header ul.mobile-nav li.nav-item a {
		padding: 0;
	}
	header .mobile-header ul.mobile-nav li.nav-item {
		padding: 10px 23px !important;
	}
	header .mobile-header ul.mobile-nav li.nav-item button.total-cart-icon {
		padding: 0;
	}
}
/* responsive ends here */
/* Header ends here */
/* page header starts here */
.page-header {
	background: #131313;
	padding: 37px 0px;
	text-align: center;
}

.page-header h2 {
	color: #ffffff;
	font-size: 38px;
	line-height: 1;
}

.page-header span {
	color: #656565;
	font-family: "Montserrat";
	font-size: 14px;
	font-weight: 400;
}

/* page header ends here */
/* banner starts here */
.banner-sec {
	overflow-x: hidden;
	margin-top: -104px;
	position: relative;
	top: 0px;
	z-index: 0;
}

.banner-sec::-webkit-scrollbar {
	width: 0px;
}

.banner-sec .banner-slider-wrapper {
	height: 100vh;
	background-repeat: no-repeat;
	background-size: 100% 100%;
	padding-top: 20px;
	position: relative;
}

/* .banner-sec .banner-slider-wrapper-1 {
	background-image: url("../img/banner-1-mg.png");
} */

/* .banner-sec .banner-slider-wrapper-2 {
	background-image: url("../img/banner-2-mg.png");
} */

.banner-sec .banner-slider-wrapper-3 {
	background-image: url("../img/banner-3-mg.png");
}

.banner-sec .slick-dots {
	position: relative;
	top: -42px;
}

.banner-sec .slick-dots li button:before {
	font-size: 15px;
	color: #fff;
	opacity: 1;
}

.banner-sec .slick-dots li.slick-active button:before {
	font-size: 15px;
	color: #fff;
	opacity: 1;
	content: "";
	width: 28px;
	height: 12px;
	background: #fff;
	border-radius: 12px;
	margin-top: 3px;
	margin: 3px 0px;
}

.banner-slider-wrapper .banner-content-wrapper h1 {
	color: #fff;
	line-height: 1.1;
}

.banner-slider-wrapper .banner-content-wrapper h1 span {
	font-family: "Baskerville";
	background-image: linear-gradient(
		to right,
		#fbc926,
		#f9cc3a,
		#f7cf4a,
		#f5d258,
		#f3d566,
		#efd166,
		#eacc65,
		#e6c865,
		#debb58,
		#d6af4c,
		#cea23f,
		#c69633
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.banner-slider-wrapper .banner-content-wrapper p {
	color: #fff;
}

.banner-sec .banner-content-wrapper a.btn-text {
	font-family: "Poppins";
	font-size: 17px;
	text-decoration: underline;
	color: #fff;
	font-weight: 500;
	padding: 10px 40px;
}

.banner-sec .banner-content-wrapper a.btn-text {
	font-family: "Poppins";
	font-size: 17px;
	text-decoration: underline;
	color: #fff;
	font-weight: 500;
	padding: 10px 40px;
}

.banner-sec .banner-slider-wrapper .banner-content-wrapper {
	position: relative;
	z-index: 9;
	display: flex;
	height: 100%;
	align-items: center;
	max-width: 70%;
	padding-left: 14%;
}

.banner-sec .banner-slider-wrapper::after {
	content: url("../img/banner-after.webp");
	position: absolute;
	bottom: -9px;
	right: 0px;
}

/* responsive starts here */
@media (max-width: 1440px) {
	.banner-sec .banner-slider-wrapper {
		height: 745px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.banner-slider-wrapper .banner-content-wrapper h1 {
		font-size: 60px;
	}
}

@media (max-width: 1280px) {
	.banner-slider-wrapper .banner-content-wrapper p br {
		display: none;
	}
}

@media (max-width: 1100px) {
	.banner-slider-wrapper .banner-content-wrapper h1 {
		font-size: 49px;
	}
}

@media (max-width: 768px) {
	.banner-sec .banner-slider-wrapper .banner-content-wrapper {
		max-width: 95%;
		padding-left: 7%;
	}
}
/* responsive ends here */
/* banner ends here */
/* we are starts here */
.we-are {
	padding: 100px 0px;
}

.we-are .we-are-content ul {
	padding: 0px;
	list-style: none;
}

.we-are .we-are-content ul li {
	line-height: 2;
}

.we-are a.btntext {
	font-family: "Poppins";
	font-size: 17px;
	text-decoration: underline;
	color: #000;
	font-weight: 500;
	padding: 10px 0px;
}

.we-are .img-wrapper {
	position: relative;
}

.we-are .img-wrapper img.img-fluid {
	width: 100%;
}

.we-are .img-wrapper img.brush {
	position: absolute;
	bottom: -36px;
	right: 6%;
}

/* responsive starts here */
@media (max-width: 1100px) {
	.we-are .we-are-content ul li span {
		font-size: 14px;
	}
}

/* responsive ends here */
/* we are ends here */
/* mission starts here */
.mission-sec {
	background-image: url("../img/mission-bg.webp");
	background-position: top;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	padding: 34px 0px;
	min-height: 491px;
}

.mission-sec figure {
	margin: 0px;
}

.mission-sec h6 {
	color: #fff;
	font-size: 16px;
}

.mission-sec h2 {
	color: #fff;
}

.mission-sec p {
	color: #fff;
}

/* responsive starts here */
@media (max-width: 1440px) {
	.mission-sec {
		background-position: left !important;
	}
}

@media (max-width: 1100px) {
	.mission-sec h2 {
		color: #fff;
		font-size: 40px;
	}
}

/* responsive ends here */
/* mission ends here */
/* traniee starts here */
.meet-tranies {
	padding: 106px 0px;
}

.meet-tranies h2 {
	color: #433f40;
}

.mission-sec .img-wrapper img {
	height: 516px;
	position: relative;
	top: -59px;
	object-fit: cover;
	object-position: right;
}

.meet-tranies .content-wrapper {
	text-align: center;
}

.tranies-wrapper .tranie-content-wrapper {
	width: 80%;
	background: #2b2724;
	padding: 11px 0px;
	text-align: center;
	margin: 0px auto;
	box-shadow: -1px 1px 5px -1px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: -1px 1px 5px -1px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: -1px 1px 5px -1px rgba(0, 0, 0, 0.75);
	margin-top: -55px;
	position: relative;
}

.tranies-wrapper .tranie-content-wrapper h3 {
	color: #fff;
	margin: 0px;
}

@media (min-width: 1400px) {
	.tranies-wrapper .tranie-content-wrapper h3 {
		font-size: 28px;
	}
}
.meet-tranies .tranies-wrapper {
	margin: 21px 0px;
}
@media (max-width: 991px) {
	.mission-sec .img-wrapper img {
		height: 180px;
		position: relative;
		top: -59px;
		object-fit: cover;
		object-position: right;
		width: 100%;
	}
	.meet-tranies,
	.row {
		padding-bottom: 0px !important;
		gap: 0 !important;
	}
}
/* traniee ends here */
/* classes starts here */
.our-classses {
	background-image: url("../img/classes-bg.webp");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	display: flex;
	align-items: end;
	/* height: 63vh; */
}

.our-classses .img-wrapper figure {
	margin: 0px;
}

.our-classses .img-wrapper figure img {
	height: 450px;
}

.our-classses .classes-content-wrapper h5 {
	color: #dedede;
	font-size: 16px;
	line-height: 1;
}

.our-classses .classes-content-wrapper h2 {
	color: #ffffff;
	font-size: 49px;
}

.our-classses .makeup-box-wrapper .boxes-wrapper {
	padding: 11px 0px;
	text-align: center;
	border-bottom: 1px solid #707070;
	border-right: 1px solid #707070;
}

.chat-img1 img {
	height: 60px;
	width: 60px;
	object-fit: cover;
}

.makeup-box-wrapper .col-lg-4 {
	padding: 0px;
}

.our-classses .makeup-box-wrapper .boxes-wrapper h6 {
	color: #fff;
	font-size: 14px;
	margin-top: 7px;
}

.our-classses .makeup-box-wrapper .col-lg-4:last-child .boxes-wrapper {
	border: 0px;
}

.our-classses .makeup-box-wrapper .col-lg-4:nth-child(3) .boxes-wrapper {
	border-right: 0px;
}

.our-classses .makeup-box-wrapper .col-lg-4:nth-child(4) .boxes-wrapper {
	border-bottom: 0px;
}

.our-classses .makeup-box-wrapper .col-lg-4:nth-child(5) .boxes-wrapper {
	border-bottom: 0px;
}

/* respponsive starts here */
/* @media (max-width: 1440px) {
	.our-classses {
		height: 74vh;
	}
} */

@media (max-width: 1280px) {
	.our-classses {
		/* height: 74vh; */
		background-size: 100% 100%;
		background-position: center;
	}
}
@media (max-width: 991px) {
	.our-classses .img-wrapper figure img {
		height: 260px;
		width: 100%;
	}
	.our-classses .makeup-box-wrapper .boxes-wrapper {
		margin-bottom: 10px;
	}
}
/* responsive ends here */
/* classes ends here */
/* video btn starts here */
.video-sec h6 {
	color: #2b2724;
}

.video-sec h2 {
	color: #433f40;
}

.video-sec p {
	color: #534f4d;
}

.video-sec {
	padding: 40px 0px;
}

.video-sec .content-wrapper {
	text-align: center;
}

.video-sec .video-thumbnail-wrapper {
	position: relative;
}

.video-sec .video-thumbnail-wrapper .video-play-btn {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.video-sec .video-thumbnail-wrapper .video-play-btn button {
	background: transparent;
	border: 0px;
}
@media (max-width: 991px) {
	.video-sec .content-wrapper {
		padding-bottom: 30px;
	}
}
/* video btn ends here */
/* client starts here */
.client-sec {
	background-image: url("../img/client-bg.webp");
	background-repeat: no-repeat;
	background-size: 100% 100%;
	margin: 50px 0px;
	/* height: 68vh; */
	display: flex;
	align-items: end;
}

.client-sec figure {
	margin: 0pc;
}

.client-sec .img-rating-wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.client-sec .img-rating-wrapper .client-name-wrapper {
	flex: 0 0 60%;
}

.client-sec .img-rating-wrapper .img-wrapper {
	flex: 0 0 20%;
}

.client-sec .img-rating-wrapper .quote-wrapper {
	flex: 0 0 20%;
}

.client-sec .img-rating-wrapper .client-name-wrapper ul {
	padding: 0px;
	list-style: none;
	display: flex;
}

.client-sec .client-content p {
	color: #ffffff;
	font-size: 12px;
	font-family: "Poppins";
}

.client-sec .client-content {
	margin-top: 31px;
}

.client-sec .img-rating-wrapper .client-name-wrapper h3 {
	color: #ffffff;
	font-size: 27px;
	line-height: 1;
}

.client-sec .client-heading-wrapper h2 {
	color: #ffffff;
	font-size: 40px;
	line-height: 1.4;
}

.client-sec .client-heading-wrapper {
	margin-bottom: 35px;
}

.client-sec .img-rating-wrapper .client-name-wrapper ul li i {
	background-image: linear-gradient(
		to right,
		#fbc926,
		#f9cc3a,
		#f7cf4a,
		#f5d258,
		#f3d566,
		#efd166,
		#eacc65,
		#e6c865,
		#debb58,
		#d6af4c,
		#cea23f,
		#c69633
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	margin: 0px 3px 0px 0px;
}

.client-sec ul.slick-dots {
	display: flex !important;
}

.client-sec ul.slick-dots li button::before {
	color: #fac929;
	font-size: 10px;
	opacity: 1;
}

.blog-img-wrapper img {
	width: 100%;
	object-fit: cover;
	height: 350px;
}

.tranies-img-wrapper img {
	width: 100%;
	height: 350px;
	object-fit: cover;
}

.client-sec ul.slick-dots li.slick-active button::before {
	content: "";
	width: 23px;
	height: 10px;
	background: #fac929;
	border-radius: 19px;
	margin-top: 4px;
}

.client-sec .slick-prev:before {
	opacity: 0;
}

.client-sec .slick-next:before {
	content: "\f061";
	font-family: "FontAwesome";
}

.client-sec .slick-next {
	left: 30%;
	top: 106%;
	z-index: 9;
}

.client-sec .slick-dots {
	position: absolute;
	bottom: -21px;
	display: block;
	width: 100%;
	padding: 0;
	margin: 0;
	list-style: none;
	text-align: center;
}

/* responssive starts here */
/* @media (max-width: 1440px) {
	.client-sec {
		height: 75vh;
	}
} */

@media (max-width: 1100px) {
	.client-sec .client-heading-wrapper {
		margin-bottom: 15px;
	}

	.client-sec .client-heading-wrapper h2 {
		font-size: 40px;
	}

	.client-sec .client-content p {
		font-size: 13px;
	}
}
@media (max-width: 991px) {
	.tranies-img-wrapper img {
		height: 280px;
	}
}
@media (max-width: 768px) {
	.blog-img-wrapper img {
		height: 145px;
	}
	.blogs .heading-content-wrapper {
		padding-bottom: 10px;
	}
}
/* client ends here */
/* blog starts here */
.blogs {
	padding: 100px 0px;
}

.blogs .heading-content-wrapper {
	text-align: center;
	padding-bottom: 42px;
}

.blogs .heading-content-wrapper h2 {
	color: #433f40;
}

.blogs .heading-content-wrapper h6 {
	color: #2b2724;
}

.blogs .heading-content-wrapper p {
	color: #534f4d;
}

.blogs .blog-card-wrapper {
	margin: 30px 0px;
}

.client-sec .img-rating-wrapper .img-wrapper img {
	width: 87px;
	border-radius: 50%;
	height: 87px;
	object-fit: cover;
	object-position: right;
}

.blogs .blog-card-wrapper h3 {
	color: #433e3b;
	font-size: 26px;
}

.blogs .blog-card-wrapper p {
	color: #433e3b;
}

.blogs .blog-card-wrapper span {
	color: #433e3b;
	font-family: "Baskerville";
	font-size: 18px;
}

.blogs .blog-card-wrapper a {
	color: #433e3b;
	margin-left: 28px;
	font-size: 18px;
	font-family: "Baskerville";
}
@media (max-width: 991px) {
	.blogs .heading-content-wrapper {
		padding-bottom: 0px;
	}
	.blogs .heading-content-wrapper p {
		font-size: 11px;
	}
	.blogs .blog-card-wrapper {
		margin: 30px 0px 0;
	}
}
/* blog ends here */
/* Footer starts here */
footer#footer {
	background-image: url("../img/footer-bg.webp");
	background-repeat: no-repeat;
	background-size: 100% 100%;
	padding: 128px 0px;
}

footer#footer .footer-links-wrapper {
	padding-top: 70px;
}

footer#footer .footer-links-wrapper .footer-widget-wrapper {
	text-align: center;
	border-right: 2px solid #ffffff;
	height: 190px;
}

footer#footer
	.footer-links-wrapper
	.col-lg-4:last-child
	.footer-widget-wrapper {
	border-right: 0px;
}

footer#footer .footer-links-wrapper .footer-widget-wrapper ul {
	list-style: none;
	padding: 0px;
}

footer#footer
	.footer-links-wrapper
	.footer-widget-wrapper
	.footer-social-icons-wrapper
	ul {
	display: flex;
	justify-content: center;
	margin-top: 23px;
}

footer#footer
	.footer-links-wrapper
	.footer-widget-wrapper
	.footer-social-icons-wrapper
	ul
	li {
	margin: 0px 6px;
}

footer#footer
	.footer-links-wrapper
	.footer-widget-wrapper
	.footer-social-icons-wrapper
	ul
	li
	i {
	color: #ffff;
	font-size: 22px;
}

footer#footer
	.footer-links-wrapper
	.footer-widget-wrapper
	.footer-heading-wrapper {
	margin-bottom: 30px;
}

footer#footer
	.footer-links-wrapper
	.footer-widget-wrapper
	.footer-heading-wrapper
	h4 {
	color: #ffffff;
	font-family: "Poppins";
	font-size: 19px;
	font-weight: 600;
	text-decoration: underline;
}

footer#footer
	.footer-links-wrapper
	.footer-widget-wrapper
	.footer-content-wrapper
	p {
	margin: 8px 0px;
	color: #fff;
	font-size: 14px;
}

footer#footer
	.footer-links-wrapper
	.footer-widget-wrapper
	.footer-content-wrapper
	ul
	li
	a {
	color: #fff;
	line-height: 1.8;
	display: block;
}

footer#footer
	.footer-links-wrapper
	.footer-widget-wrapper
	.footer-content-wrapper
	span {
	font-family: "Nunito", sans-serif;
	color: #fff;
	font-weight: 300;
}

footer#footer .footer-newsletter-wrapper {
	text-align: center;
}

footer#footer .footer-newsletter-wrapper .footer-tagline-wrapper p {
	color: #fff;
	font-size: 16px;
	padding-top: 32px;
}

.footer-content-wrapper .anker {
	color: #fff;
	font-size: 16px;
	/* padding-top: 32px; */
}

footer#footer .footer-newsletter-wrapper .newsletter-wrapper button {
	background: #000;
	padding: 0px;
	position: relative;
	right: 32px;
	top: 50%;
	color: #fff;
	font-size: 13px;
	cursor: pointer;
	width: 25px;
	z-index: 9;
}

footer#footer
	.footer-newsletter-wrapper
	.newsletter-wrapper
	input.form-control {
	background: transparent;
	border-radius: 0;
	height: 48px;
	margin-top: 23px;
	padding: 5px 45px 5px 15px;
	color: #fff;
}

footer#footer
	.footer-newsletter-wrapper
	.newsletter-wrapper
	input::placeholder {
	color: #fff;
}

/* Footer ends here */

@media (max-width: 1200px) {
	.tranies-wrapper .tranie-content-wrapper h3 {
		font-size: 24px;
		padding: 0px 1px;
	}
	.banner-sec .banner-slider-wrapper {
		height: 600px;
		margin-top: 29px;
		padding-top: 20px;
	}

	footer#footer
		.footer-links-wrapper
		.footer-widget-wrapper
		.footer-content-wrapper
		p {
		font-size: 11px;
	}

	.footer-content-wrapper .anker {
		font-size: 13px;
	}

	footer#footer
		.footer-links-wrapper
		.footer-widget-wrapper
		.footer-content-wrapper
		ul
		li
		a {
		font-size: 13px;
	}

	footer#footer
		.footer-links-wrapper
		.footer-widget-wrapper
		.footer-heading-wrapper
		h4 {
		font-size: 15px;
	}
}

@media (max-width: 991px) {
	footer#footer .footer-links-wrapper .footer-widget-wrapper {
		height: auto;
		margin: 20px 0px;
	}
	footer#footer .footer-links-wrapper {
		padding-top: 40px;
	}
	.banner-slider-wrapper .banner-content-wrapper h1 {
		font-size: 39px;
	}

	.banner-slider-wrapper .banner-content-wrapper p {
		font-size: 12px;
	}
	.our-classses .makeup-box-wrapper .boxes-wrapper {
		border: 1px solid #707070 !important;
	}
	.our-classses {
		padding: 20px 0px 90px;
	}
	.our-classses .img-wrapper figure img {
		object-fit: cover;
	}
	.makeup-box-wrapper .col-lg-4 {
		padding: 0px 15px;
	}
	.client-sec {
		padding-top: 80px;
	}

	footer#footer .footer-links-wrapper .footer-widget-wrapper {
		border: 0px;
	}
	footer#footer
		.footer-links-wrapper
		.footer-widget-wrapper
		.footer-heading-wrapper {
		margin-bottom: 10px;
	}
}

@media (max-width: 768px) {
	footer#footer .footer-newsletter-wrapper .newsletter-wrapper button {
		padding: 0 0 !important;
		position: unset;
	}
	footer#footer .footer-newsletter-wrapper .newsletter-wrapper span {
		position: absolute;
		right: 15px;
		top: 50%;
	}
	.tranies-wrapper .tranie-content-wrapper h3 {
		font-size: 21px;
	}
	.banner-sec .banner-slider-wrapper {
		height: 552px;
	}
}

@media (max-width: 576px) {
	.tranies-wrapper .tranie-content-wrapper h3 {
		font-size: 18px;
	}

	.tranies-wrapper .tranie-content-wrapper {
		width: 100%;
	}
	.banner-slider-wrapper .banner-content-wrapper h1 {
		font-size: 32px;
	}

	.banner-sec .banner-content-wrapper a.btn-text {
		padding: 10px 22px;
		font-size: 14px;
	}

	.banner-sec .banner-slider-wrapper .banner-content-wrapper .btn-wrapper .btn {
		font-size: 12px;
		padding: 7px 30px;
	}
	.banner-sec .banner-slider-wrapper .banner-content-wrapper .btn-text {
		margin-top: 5px;
		background-image: linear-gradient(
			90deg,
			hsl(46deg 96% 57%) 0%,
			hsl(46deg 92% 62%) 9%,
			hsl(47deg 87% 66%) 18%,
			hsl(48deg 81% 70%) 27%,
			hsl(48deg 73% 71%) 36%,
			hsl(45deg 65% 63%) 45%,
			hsl(43deg 60% 56%) 55%,
			hsl(41deg 57% 50%) 64%,
			hsl(41deg 57% 52%) 73%,
			hsl(42deg 61% 59%) 82%,
			hsl(44deg 66% 66%) 91%,
			hsl(46deg 75% 73%) 100%
		);
		padding: 13px 40px;
		background-repeat: no-repeat;
		border-radius: 0px;
		color: #0c0200;
		font-weight: 600;
		transition: 0.3s;
		display: block;
		text-align: center;
		text-decoration: none;
		font-size: 12px;
	}
	.banner-sec .banner-slider-wrapper {
		height: 422px;
		margin-top: 54px;
		padding-top: 20px;
	}
	.mission-sec .img-wrapper img {
		top: 0px;
		padding-top: 25px;
	}
}
@media (max-width: 576px) {
	h2 {
		font-size: 29px;
		line-height: 1.2;
	}
	.banner-sec .banner-slider-wrapper {
		height: 350px;
		margin-top: 60px;
	}
	.banner-slider-wrapper .banner-content-wrapper h1 {
		font-size: 24px;
	}
	.banner-slider-wrapper .banner-content-wrapper p {
		font-size: 10px;
	}
	.client-sec .img-rating-wrapper .quote-wrapper img {
		width: 21px;
	}
	.client-sec {
		padding-top: 80px;
		padding: 80px 15px 5px;
	}

	.client-sec .img-rating-wrapper .quote-wrapper img {
		width: 21px;
	}

	.client-sec .img-rating-wrapper .img-wrapper img {
		height: 54px;
		width: 54px;
	}

	.client-sec .img-rating-wrapper .client-name-wrapper h3 {
		font-size: 12px;
		margin-bottom: 1px;
	}

	.client-sec .slick-next::before {
		font-size: 12px;
	}

	.client-sec .slick-next {
		right: 2%;
		top: 108%;
		left: unset;
	}

	.client-sec .img-rating-wrapper .client-name-wrapper ul {
		margin-bottom: 0px;
	}

	.client-sec .img-rating-wrapper .client-name-wrapper {
		margin-left: 12px;
	}
	.client-sec .client-content p {
		font-size: 11px;
	}
	.we-are,
	.meet-tranies,
	.blogs {
		padding: 20px 0px 50px;
	}
	.big-img img {
		max-height: 100%;
		object-fit: cover;
		width: 100%;
		height: 250px;
	}
	.btn {
		padding: 7px 13px;
		font-size: 13px;
	}
	.client-sec .client-heading-wrapper h2 {
		font-size: 26px;
	}
	.our-classses .classes-content-wrapper h2 {
		font-size: 29px;
	}
	footer#footer {
		padding: 128px 0px 50px;
	}
	.we-are,
	.meet-tranies,
	.blogs {
		padding-bottom: 20px;
	}
	.video-sec {
		padding-bottom: 20px;
	}
	.client-sec {
		margin-bottom: 20px;
		margin-top: 0;
	}
	.classes-sec {
		padding-bottom: 50px;
	}
	section.contact-sec {
		margin-top: 30px;
	}
	section.we-are {
		padding-top: 24px;
	}
	.we-are .we-are-content .card-text p {
		font-size: 12px;
		margin-bottom: 15px;
	}
}
